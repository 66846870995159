<template>
<div>
    <v-container class="px-0">
        <v-col cols="12">
            <v-row>
                    <!-- <high-charts-drill-bar 
                        :id="1" 
                        :items="itemsDashboard" 
                        :visibleTitle="true" 
                        :title="'Exigible Capital + Interes'" 
                        :objDrilldown="objDrilldown"
                        :name="''"
                        :valueSuffix="'USD'"
                     /> -->

                    <v-col cols="12" lg="6" md="6">
                        <high-charts-pie :id="1" :items="itemsDashboard" :visibleSubTitle="true" :subtitle="'TOTAL CAPITAL  - EXIGIBLE'" :valueSuffix="'USD'" />
                    </v-col>

                    <v-col cols="12" lg="6" md="6">
                        <high-charts-pie :id="2" :items="itemsDashboardInteres" :visibleSubTitle="true" :subtitle="'TOTAL INTERES  - EXIGIBLE'" :valueSuffix="'USD'" />
                    </v-col>
            </v-row>
        </v-col>
    </v-container>
</div>
</template>

<script>
import HighChartsDrillBar from '@/components/Utils/HighCharts/HighChartsDrillBar.vue';
import HighChartsPie from '@/components/Utils/HighCharts/HighChartsPie.vue';
export default {
    props: {

        itemsDashboard: {
            type: Array,
            default: []
        },

        itemsDashboardInteres: {
            type: Array,
            default: []
        },


    },
    components: {
        HighChartsDrillBar,
        HighChartsPie
    },
    data() {
        return {

        };
    },
};
</script>

<style>
.tran {
    opacity: 0.7 !important;
}
</style>
